import React from 'react';
import { Flex, Box } from 'components/ReflexBox';
import { getSrc } from 'gatsby-plugin-image';

import Page from 'components/Page';
import ContentSection from 'components/ContentSection';
import SectionIntro from 'components/SectionIntro';
import ProjectList from 'components/ProjectList';
import ProjectTeaser from 'components/ProjectTeaser/Small';
import CollaborationButton from 'components/CollaborationButton';

import useFeaturedProjects from 'hooks/useFeaturedProjects';
import useProjectCount from 'hooks/useProjectCount';

const metaTags = {
  title: 'webkid - portfolio',
  author: 'Webkid',
  twitterUsername: '@wbkd',
  description:
    'All webkid projects – data visualizations, interactive maps and open source',
  siteUrl: 'https://webkid.io/portfolio/',
  robots: 'index,follow',
};

const Portfolio = () => {
  const featuredProjects = useFeaturedProjects(8);
  const projectCount = useProjectCount();

  return (
    <Page metaTags={metaTags} showBanner>
      <ContentSection centered>
        <SectionIntro
          title="Portfolio"
          text={`Over the last few years we have worked on ${projectCount} projects for various clients. Explore all of our visualizations, interactive maps and data projects.`}
        />

        <Flex
          marginX={[0, 0, -1]}
          flexWrap="wrap"
          mb={[6, 7]}
          justifyContent="space-between"
        >
          {featuredProjects.map((project) => {
            const imagePath = getSrc(
              project.imageTeaserText?.childImageSharp?.gatsbyImageData
            );

            return (
              <Box
                width={[1, 1, 1, 49 / 100]}
                px={[0, 0, 1]}
                pb={[3, 3, 4]}
                key={project.title}
              >
                <ProjectTeaser
                  slug={project.slug}
                  kicker={project.type}
                  title={project.title}
                  isDark={project.isDark}
                  text={project.description}
                  imgSrc={imagePath}
                  imgPosition="right bottom"
                  hoverEffect="scale"
                />
              </Box>
            );
          })}
        </Flex>

        <ProjectList />
        <CollaborationButton my={[6, 7]} />
      </ContentSection>
    </Page>
  );
};

export default Portfolio;
