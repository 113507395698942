import React, { useRef } from 'react';
import styled from '@emotion/styled';
import useSize from 'hooks/useSize';
import { scaleTime, scaleLinear } from 'd3-scale';
import { line, area, curveCatmullRom } from 'd3-shape';

import { Box } from 'components/ReflexBox';

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

function groupByMonth(data) {
  const obj = {};

  data.forEach((d) => {
    const date = new Date(d.date.getFullYear(), 0, 1);
    const iso = date.getTime();
    obj[iso] = obj[iso] ? obj[iso] + 1 : 1;
  });

  return Object.entries(obj).map((entry) => ({
    date: new Date(+entry[0]),
    value: entry[1],
  }));
}

const dateExtent = [new Date(2014, 0, 1), new Date(2020, 0, 1)];

const Sparkline = ({ data, color }) => {
  const chartData = groupByMonth(data);
  console.log(chartData);
  const wrapperRef = useRef();
  const { width, height } = useSize(wrapperRef);
  const x = scaleTime().domain(dateExtent).range([0, width]);
  const y = scaleLinear().domain([0, 20]).range([height, 0]).clamp(true);

  const l = line()
    .x((d) => x(d.date))
    .y((d) => y(d.value))
    .curve(curveCatmullRom);

  const a = area()
    .x((d) => x(d.date))
    .y0((d) => y(0))
    .y1((d) => y(d.value))
    .curve(curveCatmullRom);

  return (
    <Wrapper ref={wrapperRef}>
      <svg width={width} height={height}>
        <path d={l(chartData)} fill="none" stroke={color} strokeWidth={2} />
        <path d={a(chartData)} fill={color} fillOpacity={0.3} stroke="none" />
      </svg>
    </Wrapper>
  );
};

export default Sparkline;
