import React from 'react';
import { Flex, Box } from 'components/ReflexBox';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { H2, H4, Text } from 'components/Typo';
import RandomProjectButton from './RandomProjectButton';
import Sparkline from './Sparkline';

const SparklineWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const ContentWrapper = styled.div`
  z-index: 100;
  position: relative;
`;

const BigNumber = styled(H2)`
  margin: 0;
  color: ${(p) => p.color};
`;

const GridItem = ({ title, data, color }) => {
  return (
    <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 4]} px={[0, 0, 2, 2, 2]} py={2}>
      <Box
        bg="dark.cardBackground"
        px={3}
        pt={2}
        pb={3}
        height="100%"
        style={{ position: 'relative' }}
      >
        <ContentWrapper>
          <BigNumber color={color}>{data.length}</BigNumber>
          <Text>{title}</Text>
        </ContentWrapper>
        <SparklineWrapper>
          <Sparkline data={data} color={color} />
        </SparklineWrapper>
      </Box>
    </Box>
  );
};

const ProjectStats = ({ projects }) => {
  const theme = useTheme();

  const dataVizProjects = projects.filter(
    (d) => d.type === 'Data Visualization'
  );
  const mapProjects = projects.filter((d) => d.type === 'Map');
  const otherProjects = projects.filter(
    (d) => d.type !== 'Data Visualization' && d.type !== 'Map'
  );

  return (
    <Flex mx={[0, 0, -2, -2, -2]} flexWrap="wrap">
      <GridItem
        title="Data Visualizations"
        data={dataVizProjects}
        color={theme.colors.red}
      />
      <GridItem
        title="Interactive Maps"
        data={mapProjects}
        color={theme.colors.mint}
      />
      <GridItem
        title="Others"
        data={otherProjects}
        color={theme.colors.orange}
      />
      <RandomProjectButton projects={projects} />
    </Flex>
  );
};

export default ProjectStats;
