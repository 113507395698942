import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { parseProjectDate, filterPublished } from 'utils/project-utils';

export default function useFeaturedProjects() {
  const rawData = useStaticQuery(graphql`
    query ProjectListQuery {
      projects: allProjectsJson {
        edges {
          node {
            title
            createdAt
            url
            client
            type
          }
        }
      }
      featuredProjects: allMdx(
        filter: { fields: { slug: { regex: "/projects/" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              createdAt
              url
              client
              type
              published
            }
          }
        }
      }
    }
  `);

  const data = useMemo(() => {
    const featuredProjects = rawData.featuredProjects.edges
      .map(({ node }) => ({
        node: {
          id: node.fields.slug,
          ...node.fields,
          ...node.frontmatter,
          isFeatured: true,
        },
      }))
      .filter(({ node }) => filterPublished(node));

    const edges = rawData.projects.edges.concat(featuredProjects);

    const projects = edges
      .map(({ node }) => {
        node.date = parseProjectDate(node.createdAt);
        node.year = node.date.getFullYear();
        return node;
      })
      .sort((a, b) => b.date - a.date);

    return projects;
  }, [rawData]);

  return data;
}
