import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from 'components/ReflexBox';
import { Link } from 'gatsby';

import { groupProjectsByYear } from 'utils/project-utils';
import { getThemeColor, getThemeSpacePx, device } from 'utils/css-utils';
import Icon from 'components/Icon';
import { Text } from 'components/Typo';
import useProjectList from 'hooks/useProjectList';
import ProjectStats from './ProjectStats';

const ListWrapper = styled(Box)`
  margin-top: ${(p) => (p.index === 0 ? 0 : p.theme.spacePx[1])};
  padding-top: ${(p) => p.theme.spacePx[2]};
  border-top: 1px solid ${getThemeColor('stroke')};
  margin-left: -10px;
  margin-right: -10px;
`;

const ListItem = styled(Box)`
  padding: 10px;
  visibility: ${(p) => (p.isHidden ? 'hidden' : 'visible')};
  text-transform: ${(p) => (p.uppercase ? 'uppercase' : 'none')};
  display: flex;
  align-items: center;

  a,
  a:visited,
  a:focus,
  a:active {
    color: ${getThemeColor('text')};
    font-weight: 700;

    &:hover {
      color: ${getThemeColor('silverDarken30')};
    }
  }

  svg {
    margin-left: ${getThemeSpacePx(2)};
  }
`;

const FeaturedProject = styled(Flex)`
  align-items: center;
  margin-bottom: ${getThemeSpacePx(1)};

  .icon {
    margin-right: ${getThemeSpacePx(2)};
  }
`;

const DateListItem = styled(ListItem)`
  display: ${(p) => (p.isHidden ? 'none' : 'block')};

  @media ${device.tablet} {
    display: block;
  }
`;

const ClientListItem = styled(ListItem)`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const List = ({ data, index }) => {
  return (
    <ListWrapper index={index}>
      {data.map((d, i) => (
        <Flex key={d.title} flexWrap={['wrap', 'wrap', 'nowrap']}>
          <DateListItem
            width={[1, 1, 'auto']}
            color="textLight"
            isHidden={i > 0}
          >
            {d.year}
          </DateListItem>
          <ListItem width={1 / 2}>
            {d.isFeatured ? (
              <Link to={d.slug}>{d.title}</Link>
            ) : (
              <a href={d.url}>{d.title}</a>
            )}
            {d.isFeatured && (
              <Icon size={28} name="diamond" strokeColor="red" colorizeStroke />
            )}
          </ListItem>
          <ClientListItem width={1 / 4}>{d.client}</ClientListItem>
          <ListItem color="textLight" width={[1 / 2, 1 / 2, 1 / 4]}>
            {d.type}
          </ListItem>
        </Flex>
      ))}
    </ListWrapper>
  );
};

const ProjectList = (props) => {
  const projects = useProjectList();
  const projectsByYear = groupProjectsByYear(projects);

  return (
    <Box {...props}>
      {/* <ProjectStats projects={projects} /> */}
      <FeaturedProject>
        <Icon name="diamond" strokeColor="red" colorizeStroke />
        <Text color="silverDarken30">Featured project</Text>
      </FeaturedProject>
      {Object.entries(projectsByYear)
        .sort((a, b) => b[0] - a[0])
        .map((entry, index) => (
          <List key={entry[0]} data={entry[1]} index={index} />
        ))}
    </Box>
  );
};

export default ProjectList;
